const userQueryKeys = {
  all: ["user"] as const,
  user: () => [...userQueryKeys.all, "userData"] as const,
  customerSavedSearches: () =>
    [...userQueryKeys.all, "customerSavedSearches"] as const,

  userAccountActionsList: () =>
    [...userQueryKeys.all, "userAccountActionsList"] as const,
  userAccountActionsByUserId: (userId: string, actionCategory: string) =>
    [
      ...userQueryKeys.userAccountActionsList(),
      { userId, actionCategory },
    ] as const,

  customerDetails: () => [...userQueryKeys.all, "customerDetails"] as const,
  customerDetailById: (userId: string) =>
    [...userQueryKeys.customerDetails(), { userId }] as const,
  customerCreditReports: () =>
    [...userQueryKeys.all, "customerCreditReports"] as const,
  customerCreditReportsById: (userId: string) =>
    [...userQueryKeys.customerCreditReports(), { userId }] as const,
  customerCreditReportDetails: () =>
    [...userQueryKeys.all, "customerCreditReportDetails"] as const,
  customerCreditReportDetailsById: (userId: string, creditAppId: string) =>
    [
      ...userQueryKeys.customerCreditReportDetails(),
      { userId, creditAppId },
    ] as const,
  customerTradeIn: () => [...userQueryKeys.all, "customerTradeIn"] as const,
  customerTradeInById: (userId: string) =>
    [...userQueryKeys.customerTradeIn(), { userId }] as const,
};

export default userQueryKeys;
